<template>
  <div
    v-loading="verificationLoading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >  
    <template>
      <!-- Step - 1 Email Verification -->
           <div
        class="no-offer-letter"
        v-if="!unlockSignaturePad && !hideVerification && !OtpSent"
      >
        <el-card class="step-card" shadow="never">
          <el-row align="middle">
            
            <el-col
              :lg="15"
              :md="15"
              :sm="15"
              :xs="24"
              style="margin-bottom: 15px"
              v-if="getSingleDocumentData && getSingleDocumentData.data &&  getSingleDocumentData.data.document_settings && getSingleDocumentData.data.document_settings.secure_e_sign && getSingleDocumentData.data.document_settings.secure_e_sign.notify_through.includes('Send_Sms') ||  getSingleDocumentData && getSingleDocumentData.notifythrough && getSingleDocumentData.notifythrough.notify_through.includes('Send_Sms')"
             >
              <h3 class="blue-dark font-weight-normal mb-0">
                <i class="el-icon-message"></i> Phone Verification
              </h3>
              <p class="text-info mb-0">
                Verify your Phone number
                <br />To unlock the signature
              </p>
            </el-col>
        
            <el-col
              :lg="15"
              :md="15"
              :sm="15"
              :xs="24"
              style="margin-bottom: 15px"
              v-else
             >
              <h3 class="blue-dark font-weight-normal mb-0">
                <i class="el-icon-message"></i> Email Verification
              </h3>
              <p class="text-info mb-0">
                Verify your email id "
                <u>{{ getAuthenticatedUser.email }}</u
                >" <br />To unlock the signature
              </p>
            </el-col>
            
            <el-col :lg="9" :md="9" :sm="9" :xs="24" v-if="getSingleDocumentData.notifythrough">
              <el-button v-if="getSingleDocumentData.notifythrough.notify_through.includes('Send_Email')" @click="sendVerificationCode" round type="primary">
                Send Otp To Email
                <i class="el-icon-right"></i> </el-button
              ><br /><br />
              <el-button v-if="getSingleDocumentData.notifythrough.notify_through.includes('Send_Sms')"
                @click="sendVerificationCodeToPhone"
                round
                type="primary"
              >
                Send Otp To Phone
                <i class="el-icon-right"></i>
              </el-button>
            </el-col>
            <el-col :lg="9" :md="9" :sm="9" :xs="24" v-else>
              <el-button v-if="getSingleDocumentData.data.document_settings.secure_e_sign.notify_through.includes('Send_Email')"  @click="sendVerificationCode" round type="primary">
                Send Otp To Email
                <i class="el-icon-right"></i> </el-button
              ><br /><br />
              <el-button 
              v-if="getSingleDocumentData.data.document_settings.secure_e_sign.notify_through.includes('Send_Sms')"
                @click="sendVerificationCodeToPhone"
                round
                type="primary"
              >
                Send Otp To Phone
                <i class="el-icon-right"></i>
              </el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <div v-if="OtpSent">
        <!-- {{ OtpSent }} -->
        <!-- <el-row > -->
        <!-- <el-col> -->
        <el-form ref="form" label-position="top">
          <el-form-item
            label="Enter Mobile Number"
            style="margin-top: 5px; margin-bottom: 10px"
          >
          <div class="vue-phone-validator" >
   
   <VuePhoneNumberInput
   style="margin-top: 5px; margin-bottom: 10px"
        v-model="mobile"
         @update="onUpdate"
         @keydown.native="checkSignature"
            @input="hideAlert = true"
       />
   </div>
            <!-- <el-input
              phalceholder="Enter Mobile  Number"
              v-model="mobile"
              @keydown.native="checkSignature"
              @input="hideAlert = true"
            ></el-input> -->
          </el-form-item>
          <p></p>
        </el-form>
        <!-- </el-col> -->
        <!-- </el-row> -->
      </div>
      <!-- if mobile number is not stored -->

      <!-- Step -2  Valid Verfication Code -->
      <div class="code-verification">
        <el-row
          v-if="unlockSignaturePad && OtpSent == false"
          type="flex"
          justify="center"
        >
          <el-col :span="20">
            <!-- <b>Verification</b> -->
            <el-form ref="form" label-width="95px">
              <el-form-item
                label="Verification"
                style="margin-top: 5px; margin-bottom: 10px"
              >
                <el-input
                  phalceholder="Enter Verificaiton code"
                  v-model="verificationCode"
                  @keydown.native="checkSignature"
                  @input="hideAlert = true"
                ></el-input>
              </el-form-item>
              <p>
                <i class="el-icon-info"></i>
                <span v-if="isMobile">Verification code sent to {{ getAuthenticatedUser.phone_number ? getAuthenticatedUser.phone_number : this.mobile}}</span> 
               <span v-else>Verification code sent to {{ getAuthenticatedUser.email }}</span> 
                <span>
                  <el-button
                  v-if="getAuthenticatedUser.phone_number || this.mobile"
                    size="mini"
                    round
                    type="warning"
                    :disalbed="sendingEmail"
                    @click="resendVerificationToMobile"
                    class="mc-button ml-1"
                  >
                    Resend mobile
                    <i class="el-icon-refresh-left"></i>
                  </el-button>
                  <el-button
                  v-else
                    size="mini"
                    round
                    type="warning"
                    :disalbed="sendingEmail"
                    @click="resendVerification"
                    class="mc-button ml-1"
                  >
                    Resend
                    <i class="el-icon-refresh-left"></i>
                  </el-button>
                </span>
              </p>
              <p
                v-if="
                  !hideAlert &&
                  getESignVerificationErrors &&
                  getESignVerificationErrors.verification_code
                "
                class="error"
              >
                {{ getESignVerificationErrors.verification_code }}
              </p>
              <p
                v-else-if="
                  !hideAlert &&
                  getESignVerificationErrors &&
                  getESignVerificationErrors.critical_error
                "
                class="error"
              >
                {{ getESignVerificationErrors.critical_error }}
              </p>
              <p v-else></p>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div
        class="signature-box-type-two"
        v-if="verificationCode || hideVerification"
      >
        <MySignatureFromVerificationCode
          ref="mySignatureFromVerificationCodePad"
        />
      </div>
    </template>
    <div
      slot="footer"
      class="dialog-footer text-right"
      style="margin-bottom: 5px"
    >
      <el-button @click="cancelESign"> Cancel </el-button>

      <el-button
        type="primary"
        v-if="hideVerification"
        :disabled="verifyBtnDisabled"
        @click="saveUnsecureSignature()"
        >Verify & Save Secure Signature</el-button
      >
      <el-button
        type="primary"
        v-else-if="!hideVerification && !OtpSent"
        :disabled="verifyBtnDisabled"
        @click="saveCapturedSignature()"
        >Verify & Save Signature</el-button
      >
      <el-button
        type="primary"
        v-else
        @click="sendOtpToVerify()"
        >Send Otp </el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import textToImage from "text-to-image";
import MySignatureFromVerificationCode from "@/components/signature/MySignatureFromVerificationCode";

export default {
  components: {
    MySignatureFromVerificationCode,
    VuePhoneNumberInput: () => import("vue-phone-number-input")
  },
  name: "signature-ESignature",
  props: [
    "workflowName",
    "templateName",
    "purpose",
    "userType",
    "isSecureSign",
  ],
  computed: {
    hideVerification() {
          return !this.isSecureSign || false;
    },
    verifyBtnDisabled() {
      if (this.hideVerification) {
        return false;
      } else if (!this.unlockSignaturePad || !this.verificationCode) {
        return true;
      }
      return false;
    },
    ...mapGetters("employeeData", [
      "getSingleEmployeeData",
      "getESignVerificationSentStatus",
      "getESignVerifiedStatus",
      "getESignVerificationData",
      "getESignVerificationErrors",
      "getESignVerifiedData",
      "getUserSignature",
    ]),

    ...mapGetters("authorizedRepresentatives", [
      "getAuthRepresentativeSignature",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getSignatureUpdateStatus",
      "getUserSignature",
    ]),
    ...mapGetters("contacts", [
      "getContact"
    ]),
    ...mapGetters("documents", [
      "getSingleDocumentData"
    ]),
  },

  data() {
    return {
      mobile: null,
      isMobile:false,
      currentUserSign: null,
      activeName: "drawer",
      signatureText: "",
      signatureColor: "#000",
      verificationCode: "",
      sendingEmail: false,
      penColor: "black",
      unlockSignaturePad: false,
      images: [],
      signatureImage: null,
      signatureLoading: false,
      userSignatureData: {},
      verificationLoading: false,
      hideAlert: false,
      OtpSent: false,
    };
  },
  async mounted() {
    await this.fetchUserSignature();
    this.getSignatureImage();
    this.$store.commit(
      "employeeData/setESignVerificationErrors",
      {},
      { root: true }
    );
    // await this.$store.dispatch("auth/fetchUserSignature");
  },
  methods: {
        onUpdate(payload) {
      if (!payload.isValid) {
        this.error = true;
        // this.errorMsg = `Invalid ${this.data.label}`;
      } else {
        this.error = false;
        this.errorMsg = "";
        this.countryInfo = payload;
        // this.phoneno= this.countryInfo.formattedNumber;
      }
      // console.log(payload);
    },
    async getCurrentUserSignature() {
      await this.$store.dispatch("employeeData/fetchUserSignature");
      if (this.getUserSignature && this.getUserSignature.signature) {
        this.currentUserSign = this.getUserSignature.signature;
      }
    },
    resendVerification() {
      this.verificationLoading = true;
      this.sendingEmail = true;
      
      this.sendVerificationCode();
      this.sendingEmail = false;
      if (this.getESignVerificationSentStatus) {
        this.$message("Verification code sent successfully");
      }
      this.verificationLoading = false;
    },
    resendVerificationToMobile() {
      this.verificationLoading = true;
      
      this.sendVerificationCodeToPhone();
      if (this.getESignVerificationSentStatus) {
        this.$message("Verification code sent successfully");
      }
      this.verificationLoading = false;
    },
    async saveCapturedSignature() {
      let empty = await this.getSignatureImage();
      if (!this.verificationCode) {
        this.$notify.warning({
          title: "Warning",
          message: "Please enter verification code",
        });
        return;
      }
      this.hideAlert = false;
      if (!empty) {
        this.verificationLoading = true;
        await this.$store.dispatch("employeeData/verifyESignVerificationCode", {
          verification_id: this.getESignVerificationData._id,
          verification_code: this.verificationCode,
          signature: this.signatureImage,
        });
        this.verificationLoading = false;
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Please add signature",
        });
        return;
      }
      if (this.getESignVerifiedStatus) {
        this.$notify.success({
          title: "Success",
          message: "Signature verified successfully",
        });
        if (this.getESignVerifiedStatus) {
          let data = this.getESignVerificationData;
          data.signature = this.signatureImage;
          this.$emit("onVerfiedSignature", this.getESignVerificationData);
        }
      } else {
        if (!this.getESignVerificationErrors) {
          this.$notify.error({
            title: "Error",
            message: "Error at verifying the signature",
          });
        }
      }
    },
    async saveUnsecureSignature() {
      let empty = await this.getSignatureImage();
      if (!empty) {
        this.verificationLoading = true;
        await this.$store.dispatch("employeeData/verifyNonSecureSignature", {
          signature: this.signatureImage,
        });
        if (this.getESignVerifiedStatus) {
          this.successNotify("Signature saved successfully");
          this.$emit("onVerfiedSignature", {
            e_signature_id: this.getESignVerifiedData.e_signature_id,
            signature: this.signatureImage,
          });
        } else {
          this.errorNotify("Error at saving signature");
        }
        this.verificationLoading = false;
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "Please add signature",
        });
        return;
      }
    },
    async getSignatureImage() {
      let empty = true;

      await this.$refs.mySignatureFromVerificationCodePad.saveCapturedSignature();
      await this.getCurrentUserSignature();

      empty = this.$refs.mySignatureFromVerificationCodePad.empty;
      this.signatureImage =
        this.$refs.mySignatureFromVerificationCodePad.signatureImage;
      // console.log(
      //   ">>> getSignatureImage(), signatureImage, this.currentUserSign",
      //   this.signatureImage,
      //   this.$refs.mySignatureFromVerificationCodePad.getCurrentUserSign
      // );
      if (this.signatureImage == null) {
        this.signatureImage =
          this.$refs.mySignatureFromVerificationCodePad.getCurrentUserSign;
      }
      empty = this.signatureImage == null;

      return empty;
    },
    generateTextImage() {
      // let w = 50;
      let margin = 3;
      let fontSize = 30;
      let lineHeight = 30;
      if (this.tstyle == "FREESCPT") {
        // w = 30;
      } else if (this.tstyle == "NanumPenScript-Regular") {
        // w = 30;
      } else if (this.tstyle == "NothingYouCouldDo-Regular") {
        // w = 40;
        // lineHeight = 50;
        fontSize = 25;
      } else if (this.tstyle == "RAGE") {
        // w = 35;
        lineHeight = 35;
      } else if (this.tstyle == "KUNSTLER") {
        // w = 25;
      } else if (this.tstyle == "Shears") {
        fontSize = 36;
      } else if (this.tstyle == "Royaliya") {
        fontSize = 36;
      } else if (this.tstyle == "Brush Script MT") {
        // w = 45;
      } else if (this.tstyle == "SignPainterHouseScript") {
        lineHeight = 35;
        // w = 30;
      } else if (this.tstyle == "Segoe Script") {
        // w = 30;
        fontSize = 25;
        margin = 5;
      } else if (this.tstyle == "Lucida Handwriting") {
        // w = 35;
        // lineHeight = 35;
        fontSize = 25;
      } else if (this.tstyle == "BickleyScript") {
        fontSize = 45;
      } else if (this.tstyle == "VladimirScript") {
        fontSize = 25;
      } else if (this.tstyle == "KunstlerScript") {
        fontSize = 25;
      } else if (this.tstyle == "Mistral") {
        fontSize = 25;
      } else if (this.tstyle == "Cookie") {
        fontSize = 36;
      } else if (this.tstyle == "Playfair Display") {
        fontSize = 24;
      } else if (this.tstyle == "Kaushan Script") {
        fontSize = 36;
      } else if (this.tstyle == "Arizonia") {
        fontSize = 36;
      } else if (this.tstyle == "Tangerine") {
        fontSize = 30;
      }
      // let imgWidth =
      //   this.signatureText.length * w < 186
      //     ? 186
      //     : this.signatureText.length * w;

      // textToImage
      //   .generate(this.signatureText, {
      //     textColor: this.signatureColor,
      //     maxWidth: imgWidth,
      //     textAlign: "center",
      //     verticalAlign: "center",
      //     fontSize: 20,
      //   })
      var maxWidth = 250;
      if (this.signatureText.length >= 14) {
        maxWidth = maxWidth + (this.signatureText.length - 13) * 10 + 20;
        // lineHeight = lineHeight + (this.signatureText.length - 14) * 1;
      }
      textToImage
        .generate("  " + this.signatureText, {
          textColor: this.signatureColor,
          fontFamily: this.tstyle,
          maxWidth: maxWidth,
          textAlign: "left",
          verticalAlign: "center",
          fontSize: fontSize,
          // fontFamily: 'Arial',
          lineHeight: lineHeight,
          margin: margin,
          bgColor: "transparent",
          // textColor: 'red',
        })
        .then((dataUri) => {
          this.signatureImage = dataUri;
        });
    },
    async sendVerificationCode() {
      
      this.verificationLoading = true;
      await this.$store.dispatch("employeeData/sendESignVerification", {
        workflow_name: this.workflowName,
        template_name: this.templateName,
        purpose: this.purpose,
        isEmail: true,
      });
      this.verificationLoading = false;
      if (this.getESignVerificationSentStatus) {
        this.unlockSignaturePad = true;
      }
    },
    async sendVerificationCodeToPhone() {
      // console.log(" this.countryInfo", this.countryInfo);
      this.isMobile = true;
      this.verificationLoading = true;
      // console.log("getAuthenticatedUser",this.getAuthenticatedUser)
      await this.$store.dispatch("employeeData/sendESignVerification", {
        workflow_name: this.workflowName,
        template_name: this.templateName,
        purpose: this.purpose,
        isMobile: this.isMobile,
        mobileNo :  !this.countryInfo || !this.countryInfo.formattedNumber?"":this.countryInfo.formattedNumber
      });
      this.verificationLoading = false;
      // console.log(
      //   "this.getESignVerificationData",
      //   this.getESignVerificationData
      // );
      if (this.getESignVerificationData.status == false) {
        // console.log("test");
        this.OtpSent = true;
        this.unlockSignaturePad = true;
      }
      else{
        this.OtpSent = false;    
          this.unlockSignaturePad = true;

      }
    },
    async sendOtpToVerify(){
      this.verificationLoading = true;
      this.sendVerificationCodeToPhone();
      this.verificationLoading = false;
    },
    async verifyCode() {
      await this.$store.dispatch("employeeData/verifyESignVerificationCode", {
        verification_id: this.getESignVerificationData._id,
        verification_code: this.verificationCode,
      });
      if (this.getESignVerifiedStatus) {
        this.$emit("onVerfiedSignature", this.getESignVerificationData);
      }
    },
    cancelESign() {
      this.$emit("cancelESign");
    },

    setColor(color) {
      this.penColor = color;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async fetchUserSignature() {
      if (this.userType == "AUTHORIZED_REPRESENTATIVE") {
        await this.$store.dispatch(
          "authorizedRepresentatives/fetchAuthorizedRepresentativeSignature"
        );

        this.userSignatureData = this.getAuthRepresentativeSignature;
      } else {
        await this.$store.dispatch("employeeData/fetchUserSignature");
        this.userSignatureData = this.getUserSignature;
      }

      if (!this.isSecureSign) {
        await this.checkSignature();
      }
    },

    checkSignature() {
      this.signatureLoading = true;
      // console.log(this.userSignatureData);
      if (this.userSignatureData && this.userSignatureData.signature) {
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.$refs && this.$refs.signaturePad) {
              this.$refs.signaturePad.fromDataURL(
                this.userSignatureData.signature
              );
              this.$refs.signaturePad.lockSignaturePad();
            }
          });
          this.signatureImage = this.userSignatureData.signature;
          this.signatureLoading = false;
        }, 1000);
      } else {
        this.signatureLoading = false;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("employeeData/setESignVerificationSentStatus", null, {
      root: true,
    });
    this.$store.commit("employeeData/setESignVerifiedStatus", null, {
      root: true,
    });
    this.$store.commit("employeeData/setESignVerificationData", null, {
      root: true,
    });
    this.$store.commit("employeeData/setESignVerifiedData", null, {
      root: true,
    });
    //this.$store.commit("employeeData/setUserSignature",null,{root:true})
    this.$store.commit("employeeData/setESignVerificationErrors", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss">
.no-offer-letter {
  display: flex;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
  .el-card {
    margin: 0em 0em 1.75em;
    background-color: #f1f8ff;
    box-shadow: 0px 1px 1px rgba(34, 35, 58, 0.05);
    border: none;
    padding: 0em;
    border-radius: 2px;
    text-align: center;
    p {
      margin-bottom: 1em;
    }
  }
  .step-card {
    text-align: left;
    width: 100%;
  }
}
.signature-box-type-two {
  display: block;
  // max-width: 500px;
  max-width: none;
  margin: 0 auto 1.5em;
  margin-top: 15px !important;
  .sign-image {
    // border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 1em;
    background-color: rgba(0, 0, 0, 0.025);
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 80px;
      height: 1px;
      background-color: #999999;
      z-index: 0;
    }
  }
  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
    &.red {
      background-color: red;
    }
    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }
}
.dialog-footer {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.error {
  color: red;
}
.country-selector__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}
.input-tel__input {
  background-color: #eff3fa !important;
  height: 50px !important;
  -webkit-box-shadow: 0px 1px 2px #ddebfa !important;
  box-shadow: 0px 1px 2px #ddebfa !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 1.1em !important;
  letter-spacing: 0.5px !important;
}


</style>
